import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Movie } from '@usheru-hff/shared/domain';
import { VideoFullscreenService } from '@usheru-hff/shared/ui-video';
import { AuthService } from '@usheru-hff/authentication/domain';
import { UsheruApi } from '@usheru-hff/shared/data-access-backend';
import { CookieSettingsService } from '@usheru-hff/shared/utils-cookies';
import { LocationService } from '@usheru-hff/sdk/location';
import { TranslationsService } from '@usheru-hff/shared/utils-translation';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlayerAvailable } from '../entities/player-available.model';
import { PlayerPrice } from '../entities/player-price.model';
import { PlayerPurchased } from '../entities/player-purchased.model';
import { Player } from '../entities/player.model';
import { ENVIRONMENT, Environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class UplayerService {
  constructor(
    private locationService: LocationService,
    private authService: AuthService,
    private translationsService: TranslationsService,
    private fullScreenService: VideoFullscreenService,
    @Inject(ENVIRONMENT) private env: Environment,
    private cookiesService: CookieSettingsService,
    private usheruApi: UsheruApi,
    private http: HttpClient
  ) {}

  private playerUrl: string | null;
  private players: Player[];
  private storedAvailablePlayer: StoredAvailablePlayer;
  private movie: Movie;
  private retrievingPlayers: boolean;
  $playerPrices = new BehaviorSubject<PlayerPrice[]>(null);
  $uplayerMovieUpdated = new BehaviorSubject<Movie>(null);

  getPurchasedUplayers(): Observable<PlayerPurchased> | BehaviorSubject<PlayerPurchased> {
    if (this.isCountryAvailable) {
      return this.getPurchasedPlayers(this.getUserCountryId(), this.getUserToken(), this.translationsService.currentLanguage().code);
    } else {
      return new BehaviorSubject(null);
    }
  }

  retrieveAvailablePlayerPrices(movieId) {
    if (this.isCountryAvailable) {
      this.$playerPrices.next(null);
      this.getAvailablePlayer(movieId, this.getUserCountryId(), null, this.getUserToken()).subscribe(
        playersAvailable => {
          this.players = playersAvailable['players'];
          this.movie = playersAvailable['movie'];
          this.$uplayerMovieUpdated.next(this.movie);
          this.$playerPrices.next(this.getAvailableToWatchPLayers(playersAvailable));
        }
      );
    }
  }

  onPurchase() {
    this.storedAvailablePlayer = null;
  }

  getAvailableUplayers(movieId, discountCode): Observable<PlayerAvailable> {
    if (this.isCountryAvailable) {
      return this.getAvailablePlayer(movieId, this.getUserCountryId(), discountCode, this.getUserToken());
    }
    return null;
  }

  get isCountryAvailable() {
    return this.locationService.uplayerCountry ? true : false;
  }

  getAvailableToWatchDate(date) {
    const friendlyDate = this.getFriendlyDate(date);
    return this.isDateInFuture(date) ? friendlyDate : '';
  }

  getFriendlyDate(date) {
    return new Date(date).toLocaleDateString(this.translationsService.currentLanguage().code, {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  isDateInFuture(date) {
    return date > Date.now();
  }

  /**
   * Method that checks if there is any player that is available to
   * @param pPrice
   * @returns
   */
  getAvailableToWatchPLayers(playersAvailable: PlayerAvailable): PlayerPrice[] {
    const availablePlayerPrices: PlayerPrice[] = [];
    // add available players from playersAvailable
    playersAvailable.availablePlayers.forEach(pPrice => {
      if (this.isPlayerPriceAvailableToWatch(pPrice)) {
        availablePlayerPrices.push(pPrice);
      }
    });
    // add available players from playersPurchased
    playersAvailable.purchasedPlayers.forEach(pPrice => {
      if (this.isPlayerPriceAvailableToWatch(pPrice)) {
        availablePlayerPrices.push(pPrice);
      }
    });

    // if availablePlayerPrices has content return content else return null
    if (availablePlayerPrices.length > 0) {
      return availablePlayerPrices;
    } else {
      return null;
    }
  }

  private getUserToken() {
    return this.authService.accessToken();
  }

  private getUserCountryId() {
    return this.locationService.uplayerCountry.id;
  }

  getPlayerUrl() {
    return this.playerUrl || null;
  }

  getPlayers() {
    return this.players || null;
  }

  getMovie() {
    return this.movie || null;
  }

  getPlayerWithId(id: number): Player {
    return this.players.find(player => player.id == id);
  }

  isPlayerPriceAvailableToWatch(pPrice: PlayerPrice) {
    return (
      this.isDateRangeInPresentFuture(pPrice.availableFrom, pPrice.availableTo) ||
      this.isDateInFuture(pPrice.availableFrom)
    );
  }

  isDateRangeInPresentFuture(from, to) {
    return from <= Date.now() && to > Date.now();
  }

  getPurchasedPlayer(playerPrices: PlayerPrice[]): Player {
    let player: Player = null;
    // we dont need this iteration as the array will always be of length = 1
    playerPrices.forEach(pPrice => {
      if (pPrice.purchaseDate) {
        player = this.getPlayerWithId(pPrice.idPlayer);
      }
    });
    this.playerUrl = player?.url || null;
    if (player) {
      return player;
    } else {
      return null;
    }
  }

  getMinPrice(playerPrices: PlayerPrice[]): PlayerPrice {
    let minPrice = playerPrices[0].priceInCents;
    let minPlayerPrice = playerPrices[0];

    for (const pp of playerPrices) {
      if (pp.priceInCents < minPrice) {
        minPrice = pp.priceInCents;
        minPlayerPrice = pp;
      }
    }

    return minPlayerPrice;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderFreeVideo(playerPriceId: number, discountCode: string): Observable<any> {
    return this.postFreeVideoOrder(playerPriceId, discountCode, this.authService.accessToken());
  }

  playVideo(player: Player, movie?: Movie) {
    // check if trailer cookies are available first (it is necesary for tracking)
    if (!this.isTrailerCookiesEnabled()) {
      // ask for trailer cookies first
      this.cookiesService
        .getCookieConsentInput(['functionality_storage'])
        .then(enabled => {
          if (enabled) {
            this.playVideo(player, movie);
          } else {
            return;
          }
        })
        .catch(() => console.log('error at playing uplayer'));
    } else {
      if (!player.url) {
        return;
      }

      // // Setting up this.videoCaptions if there is any in the purchase video
      // if (playerPricePurchased.captions && playerPricePurchased.captions.src.indexOf('null') < 0) {
      //   this.videoCaptions = playerPricePurchased.captions;
      // }

      // TODO: ask Ivan to make the captions file of the type object Caption
      // if there is captions send it to the play() function

      if (movie) this.movie = movie;

      if (player.accessibleUrl) {
        this.fullScreenService.play({
          content: {
            uPlayVideo: player.url,
            title: this.movie.title
          },
          category: 'uplayer',
          animate: true,
          accessibleUrl: player.accessibleUrl
        });
      } else {
        // else just send the video
        this.fullScreenService.play({
          content: {
            uPlayVideo: player.url,
            title: this.movie.title
          },
          category: 'uplayer'
        });
      }
    }
  }

  getAvailabilityDuration(playerPrice: PlayerPrice) {
    // get number of days from duration in hours
    if (!playerPrice) return '';
    const days = playerPrice.availabilityDuration / 24;

    if (days >= 365) return Math.round(days / 365) + ' ' + (days / 365 >= 2 ? this.translationsService.translate('years') : this.translationsService.translate('year'));
    if (days >= 30) return Math.round(days / 30) + ' ' + (days / 30 >= 2 ? this.translationsService.translate('months') : this.translationsService.translate('month'));
    if (days >= 7) return Math.round(days / 7) + ' ' + (days / 7 >= 2 ? this.translationsService.translate('weeks') : this.translationsService.translate('week'));
    if (days >= 1) return days + ' ' + (days >= 2 ? this.translationsService.translate('days') : this.translationsService.translate('day'));

    return '';
  }
  // funtion to use from any component within movie detail component, it will basically will try to get the last movie used for uplayer queries
  isUplayerAvailable(movie: Movie) {
    return movie.hasPlayer;
  }

  isTrailerCookiesEnabled(): boolean {
    return this.cookiesService.getCookieSettings().functionality_storage.enabled;
  }

  //   API QUERIES -------------------------------

  getAvailablePlayer(
    idMovie: string,
    idCountry: string,
    discountCode: string,
    token?: string
  ): Observable<PlayerAvailable> {
    // check if we have availablePlayers for this movie, this country and this discount code, token before doing queries.
    if (this.availablePlayersAreRetrieved(idMovie, idCountry, discountCode, token)) {
      return of(this.storedAvailablePlayer.availablePlayer);
    }
    let params = new HttpParams();
    params = params.set('idCountry', idCountry);
    params = params.set('idMovie', idMovie);

    if (discountCode) {
      params = params.set('discountCode', discountCode);
    }

    let headers = token ? this.usheruApi.generateBearerHeader(token) : this.usheruApi.getStandardHeader();
    headers = headers.append('Cache-Control', 'no-cache');
    this.retrievingPlayers = true;
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(`${this.env.api.url}/player/available/`, {
          headers: headers,
          params: params
        })
        .pipe(
          map(res => {
            this.storedAvailablePlayer = {
              movieId: idMovie,
              countryId: idCountry,
              discountCode: discountCode,
              token: token,
              availablePlayer: res
            };
            this.retrievingPlayers = false;
            return res;
          })
        )
    );
  }

  getPurchasedPlayers(idCountry: string, token: string, trLanguage?: string): Observable<PlayerPurchased> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(`${this.env.api.url}/player/purchased/?idCountry=${idCountry}&trLanguage=${trLanguage}`, {
      headers: (token ? this.usheruApi.generateBearerHeader(token) : this.usheruApi.getStandardHeader()).append(
        'Cache-Control',
        'no-cache'
      )
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postFreeVideoOrder(idPlayerPrice: number, discountCode: string, userToken: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.post<any>(
      `${this.env.api.url}/player/free-order`,
      {
        idPlayerPrice: idPlayerPrice,
        discountCode: discountCode
      },
      {
        headers: this.usheruApi.generateBearerHeader(userToken)
      }
    );
  }

  private availablePlayersAreRetrieved(idMovie, idCountry, discountCode, token): boolean {
    if (this.storedAvailablePlayer) {
      if (
        this.storedAvailablePlayer.movieId == idMovie &&
        this.storedAvailablePlayer.countryId == idCountry &&
        this.storedAvailablePlayer.discountCode == discountCode &&
        this.storedAvailablePlayer.token == token
      ) {
        return true;
      }
    }
    return false;
  }
}

interface StoredAvailablePlayer {
  availablePlayer: PlayerAvailable;
  movieId: string;
  discountCode?: string;
  countryId: string;
  token?: string;
}
